import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import { Footer } from "../components/FooterTop";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useBrowser } from './Provider/BrowserContext';
SwiperCore.use([Navigation, Pagination, Autoplay]);

const RenderScreenshot = ({ src, alt, cls, isActive }) => {
    return src ? (
        <img
            src={src}
            loading="lazy"
            alt={`${alt} Case Study`}
            className={
                `w-full h-auto object-contain transition-transform duration-500 ` +
                cls +
                ` ` +
                (isActive == false ? "opacity-50" : "opacity-100")
            }
        />
    ) : (
        <div className="h-40 w-full bg-gray-200 flex items-center justify-center text-gray-500 mb-2">
            <span>No Image</span>
        </div>
    );
};

// Reusable Component for each website section
const WebsiteCard = ({ index, title, description, logo, imgSrc, url, logoTheme, w, h, isActive }) => (
    <div className={`container max-w-[1400px] mx-auto mb-12 z-40 transition-transform duration-500 ${isActive ? 'scale-100' : 'scale-90'}`}>
        <div className="self-stretch overflow-hidden flex flex-row items-center justify-center pr-5 pl-[21px] relative min-h-[420px] gap-[89px] mq1250:min-h-[300px] mq1250:py-[50px] max-w-full z-[1] bg-opacity-50">
            <div className="p-5 rounded-md w-full flex flex-col md:flex-row space-x-8 bg-white bg-opacity-10">
                <a className="w-full no-underline" href={url}>
                    <div className={`flex ${index % 2 === 1 ? 'flex-row-reverse' : 'flex-row'} md:flex-col w-full gap-x-8 justify-center items-center  mq650:flex-col gap-5 mq650:gap-8`}>
                        <div className="flex flex-col items-center justify-center text-white">
                            <div className={`flex items-center justify-between ${index % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'} mb-3`}>
                                <div className={`p-1 h-fit w-auto rounded-md ${logoTheme === 'dark' ? 'bg-white w-fit' : ''}`}>
                                    <img
                                        src={logo}
                                        alt={`${title} Logo`}
                                        loading="lazy"
                                        className="lazyloaded"
                                        width={w ? w : "234"}
                                        height={h ? h : "50"}
                                    />
                                </div>
                                <div className="ml-4">
                                    <h3>{title}</h3>
                                </div>
                            </div>
                            <div className="mainContainer relative p-3 text-justify rounded-md">
                                <span className="absolute inset-0 opacity-20 backdrop-blur-sm rounded-md"></span>
                                <div className="relative z-10">
                                    <h4>{description}</h4>
                                </div>
                            </div>
                        </div>
                        <div className='mq1024:min-w-[250px]'>
                            <RenderScreenshot src={imgSrc} alt={title} />
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
);

// Testimonial Swiper Component
const TestimonialSwiper = () => {
    const w = useBrowser().windowDimensions.width;

    const testimonials = [
        {
            name: 'John Doe',
            feedback: 'This is an amazing service! Our website was built perfectly according to our vision.',
            img: '/Anonymous.png',
        },
        {
            name: 'Jane Smith',
            feedback: 'Highly recommend! The team was extremely professional and delivered top-notch quality.',
            img: '/AnonymousFemale.jpg',
        },
        {
            name: 'Clint Cost',
            feedback: 'The service was fast and top-notch. Highly professional team.',
            img: '/AnonymousMale.jpg',
        },
        {
            name: 'Michael Lee',
            feedback: 'Their attention to detail and customer service is excellent! Our project was a success.',
            img: '/AnonymousMale.jpg',
        },
        {
            name: 'Sarah Connor',
            feedback: 'The service was fast and top-notch. Highly professional team.',
            img: '/AnonymousFemale.jpg',
        },
        {
            name: 'Tom Hanks',
            feedback: 'Great service, everything was delivered on time. Will definitely recommend!',
            img: '/AnonymousMale.jpg',
        },
    ];

    return (
        <div className="container mx-auto pb-12">
            <h2 className="text-4xl text-white font-bold text-center mb-8">What Our Clients Say</h2>
            <Swiper
                slidesPerView={w > 1500 ? 5 : w > 900 ? 3 : 1}
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                pagination={{ clickable: true }}
                navigation
                // autoplay={{ delay: 5000 }}
                className="testimonial-swiper"
            >
                {testimonials.map((testimonial, index) => (
                    <SwiperSlide key={index} className="testimonial-slide w-full">
                        {({ isActive }) => (
                            <div
                                className={`flex flex-col max-w-[350px] min-w-[200px] items-center text-center p-8 mx-7 rounded-lg shadow-lg transition-transform duration-300 ease-in-out z-[40] ${isActive ? 'bg-[#cea2fa] scale-100' : 'bg-[#e8d0ff] bg-opacity-20 scale-95'
                                    }`}
                            >
                                {/* <RenderScreenshot
                                    src={testimonial.img}
                                    alt={testimonial.name}
                                    cls={"rounded-[150px] transition-transform duration-300 ease-in-out"}
                                    isActive={isActive}
                                /> */}
                                <p className="text-lg mb-2">{testimonial.feedback}</p>
                                <h4 className="text-xl font-bold">-{testimonial.name}</h4>
                            </div>
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

const Portfolio = () => {
    const [w, setW] = useState(window.innerWidth);

    const [activeCards, setActiveCards] = useState([]);

    const cardRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setActiveCards(prev => [...prev, entry.target.id]);
                    } else {
                        setActiveCards(prev => prev.filter(id => id !== entry.target.id));
                    }
                });
            },
            { threshold: 0.5 }
        );

        cardRefs.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });

        const handleResize = () => {
            setW(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            cardRefs.current.forEach(ref => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    const websites = [
        {
            url: 'https://ummazing.com/',
            name: 'Umazing',
            description: 'Ummazing.com is your ultimate platform for discovering Muslim-friendly businesses. With over 15500 businesses and counting.',
            screenshots: '/Ummazing1.png',
            logo: 'https://ummazing.com/_next/image?url=%2Fassets%2Fummazing.png&w=384&q=75',
            w: 69.5,
            h: 69.5
        },
        {
            url: 'https://whitehallclinic.com/',
            name: 'Whitehall Clinic',
            description: 'Introducing the Whitehall Clinic’s new online presence, designed to reflect its status as a leading private medical practice in central Leeds.',
            screenshots: '/WhiteHall.png',
            logoTheme: 'dark',
            logo: 'https://www.whitehallclinic.com/images/logo.webp',
            w: 190,
            h: 60.5
        },
        {
            url: 'https://enconstaffing.com/',
            name: 'Encon Staffing',
            description: 'Explore Encon Staffing’s new website, dedicated to connecting top talent with leading industries including construction, cleaning, protective coating and healthcare.',
            screenshots: '/enconstaffing.png',
            logo: 'https://enconstaffing.com/wp-content/uploads/2023/02/logo.png',
            logoTheme: 'dark',
            w: 210,
            h: 60.5
        },
        {
            url: 'https://awesomeoutreach.com/',
            name: 'Awesome Outreach',
            description: 'A professional website that assists Awesome Outreach’s goal to automate LinkedIn and emails to generate leads and sales for businesses.',
            screenshots: '/awesomeoutreach.png',
            logo: 'https://awesomeoutreach.com/wp-content/uploads/2023/06/cropped-AO-Logo.png',
            logoTheme: 'dark',
            w: 147,
            h: 69.5
        }
    ];

    return (
        <div className="w-full relative bg-black overflow-hidden flex flex-col items-start justify-start leading-normal tracking-normal">
            {/* Background SVG */}
            <div className="absolute w-[2487px] h-[7910px] bottom-[-3620px] left-[-291px] z-0">
                <img
                    className="w-full h-full object-contain absolute left-0 top-[-86px] transform scale-[1.022]"
                    alt=""
                    src="/element.svg"
                />
            </div>

            <Header />

            {/* Intro Text */}
            <div className="mainContainer relative mx-auto text-center py-12 px-6">
                <h2 className="relative z-[2] text-white font-bold mb-6 font-montserrat text-23xl">Our Portfolio</h2>
                <p className="relative z-[2] text-lg text-white max-w-3xl mx-auto text-justify font-poppins">
                    We believe your website should be a true reflection of the quality of your business, as it often serves as the first impression for many of your customers.
                    We’re here to team up with you, understand your vision, and make that a reality.
                    Here are some examples of the business websites we have brought to life previously.
                </p>
            </div>





            {/* Portfolio Projects */}
            {websites.map((site, index) => (
                <div key={site.name} id={`website-card-${index}`} className='mx-auto' ref={el => (cardRefs.current[index] = el)}>
                    <WebsiteCard
                        index={index}
                        w={site.w}
                        h={site.h}
                        title={site.name}
                        logoTheme={site.logoTheme ? site.logoTheme : ''}
                        description={site.description}
                        logo={site.logo}
                        imgSrc={site.screenshots}
                        url={site.url}
                        isActive={activeCards.includes(`website-card-${index}`)}
                    />
                </div>
            ))}

            <TestimonialSwiper />

            <Footer />
        </div>
    );
};

export default Portfolio;
