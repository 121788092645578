import PropTypes from "prop-types";

const FAQHeadComponent = ({ className = "" }) => {
  return (
    <header
      className={`self-stretch h-[628px] mq1550:h-[500px] mq825:h-[350px] overflow-hidden shrink-0 flex flex-col items-center justify-center py-[150px] px-0 box-border relative gap-[10px] bg-[url('/public/faq-2-1@2x.png')] bg-cover bg-no-repeat bg-[top] max-w-full z-[1] text-left text-86xl text-light-100 font-poppins ${className}`}
    >
      <img
        className="self-stretch relative max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
        alt=""
        src="/faq-2-1@2x.png"
      />
      <div className="w-[1350px] mq1550:W-[1000px] !m-[0] absolute flex flex-row items-center justify-between py-0 px-0 box-border gap-[20px] max-w-full z-[1] mq1325:w-[1253px] mq1450:justify-around">
        <h1 className="m-0 w-[210px] mq450:w-[150px] mq825:text-13xl relative text-inherit leading-[100px] capitalize font-semibold font-inherit inline-block shrink-0 whitespace-nowrap max-w-full">
          FAQ
        </h1>
        <div className="flex flex-row items-center justify-start py-5 px-0 gap-[20.5px] text-4xl">
          <a className="[text-decoration:none] relative capitalize font-semibold text-[inherit] inline-block min-w-[70px]">
            Home
          </a>
          <img
            className="h-4 w-2.5 relative"
            loading="lazy"
            alt=""
            src="/vector-165.svg"
          />
          <b className="relative capitalize font-semibold text-primary inline-block min-w-[47px]">
            FAQ
          </b>
        </div>
      </div>
    </header>
  );
};

FAQHeadComponent.propTypes = {
  className: PropTypes.string,
};

export default FAQHeadComponent;
