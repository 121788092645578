import CardColumns from "./CardColumns";
import PropTypes from "prop-types";

const FrameComponent1 = ({ className = "" }) => {
  return (
    <div className={`self-stretch flex flex-row items-start justify-center py-0 px-1.5 box-border max-w-full text-left text-45xl text-white font-montserrat mq825:mt-10 ${className}`}>
      <div className={`max-w-[1400px] flex-1 flex flex-col items-start justify-start gap-[64px] z-[1] mq825:gap-[32px] mq450:gap-[16px]`}>
        {/* Section Header */}
        <div className="self-stretch flex flex-col items-start justify-start gap-[24px] max-w-[1400px] px-[14px] mq450:w-[360px] mq450:self-center">
          <h1 className="m-0 self-stretch relative text-inherit font-bold font-inherit mq825:text-32xl mq450:text-19xl mq450:mt-5">
            What Services We Offer
          </h1>
          <h3 className="m-0 self-stretch relative text-5xl font-normal font-poppins text-lightgray-100 mq450:text-lgi">
            We have three main categories of services to choose from.
          </h3>
        </div>

        {/* Service Cards */}
        <div className="self-stretch grid grid-cols-[repeat(3,_minmax(427px,_1fr))] gap-6 max-w-full text-14xl 
            mq1550:grid-cols-[repeat(2,_minmax(427px,_740px))] 
            mq825:grid-cols-[minmax(427px,_1fr)] 
            mq575:grid-cols-[1fr]">
          <CardColumns
            cardColumnOne="/frame-770722@2x.png"
            staticWebsiteDevelopment="Static Website Development"
            fastSecureAndSEOFriendlyS="Fast, secure, and SEO-friendly static websites to establish your online presence with ease."
            toLink="/static"
          />
          <CardColumns
            cardColumnOne="/frame-770722-1@2x.png"
            staticWebsiteDevelopment="Full Stack Web Development"
            fastSecureAndSEOFriendlyS="Comprehensive web development services from frontend to backend to build robust web applications."
            toLink="/fullstack"
          />
          <CardColumns
            cardColumnOne="/frame-770722-2@2x.png"
            staticWebsiteDevelopment="Bespoke Software"
            fastSecureAndSEOFriendlyS="Custom web designs tailored to your brand identity and business needs to stand out in the market."
            toLink="/bespoke"
          />
        </div>
      </div>
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
