import { Swiper, SwiperSlide } from "swiper/react";
import GridColumns from "./GridColumns";
import PropTypes from "prop-types";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useEffect, useState } from "react";

const NeedAWebsite = ({ className = "", gridColumnsData }) => {
  const [w, setW] = useState(window.innerWidth);
  const [activeSlide, setActiveSlide] = useState(0); // Track the active slide index
  useEffect(() => {
    const handleResize = () => {
      setW(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`flex flex-col max-w-[1400px] items-center self-center pt-0 px-5 box-border text-left text-13xl text-gray-200 font-adamina ${className}`}
    >
      <div className="min-w-[300px] flex flex-col items-center justify-start gap-10 mb-10 mq1250:w-[800px] mq825:mb-6 mq825:w-[700px] mq750:w-[550px] mq575:w-[425px] mq450:w-[325px] ">
        <h1 className="text-center text-45xl text-white font-montserrat mq825:text-32xl mq550:text-12xl mq450:text-19xl">
          Why should you invest in a website?
        </h1>
      </div>
      <div className="w-full flex flex-wrap items-start justify-around gap-[20px] mySwiper">
        <Swiper
          slidesPerView={w > 1400 ? 3 : 1}
          // spaceBetween={(w < 1280 && w > 1024) ? 1 : 30}
          modules={[Navigation, Pagination, Autoplay]}
          navigation
          // autoplay={{ delay: 5000 }}
          pagination={{ clickable: true }}
          className={`mySwiper ${w < 1280 && w > 1100 ? "MR-0" : ""}`}
          loop
        >
          {gridColumnsData.map((data, index) => (
            <SwiperSlide key={index}>
              {({ isActive }) => {
                if (isActive) setActiveSlide(index)
                return <GridColumns
                  className="flex-grow"
                  title={data.title}
                  description={data.description}
                  size={data?.size}
                  active={index === activeSlide} // Set the `active` prop based on whether this slide is active
                />
              }}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

NeedAWebsite.propTypes = {
  className: PropTypes.string,
  gridColumnsData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default NeedAWebsite;
