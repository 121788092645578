import PropTypes from "prop-types";
import { useBrowser } from "../pages/Provider/BrowserContext";

const GridColumns = ({
  className = "",
  title,
  description,
  size,
  testimonial,
  active,
}) => {
  const browserName = useBrowser();
  return (
    <div
      className={`relative flex flex-col items-start justify-start min-w-[340px] max-w-[566px] mq825:min-w-[300px] mq825:max-w-[500px] ${browserName.browser.includes("Safari") ? "mq550:min-w-[300px] mq550:max-w-[400px]" : 'mq550:max-w-[400px] mq450:max-w-[360px]'} text-left text-13xl text-gray-200 font-rubik rounded-lg mb-8 shadow-md transition-transform duration-300 ease-in-out transform ${active ? "scale-100 shadow-2xl border-4 border-indigo-500 bg-opacity-20" : "scale-90"} h-full mqAll:ml-[25px] mq1920:ml-[25px] mq1450:ml-0 ${className} `}
    >
      {/* Glassmorphism Container */}
      <div
        className={`w-full h-full ${active ? "bg-gradient-to-r from-[#c471ed] to-[#a044ff]" : "bg-white"
          } bg-opacity-10 backdrop-blur-lg border border-opacity-20 border-white rounded-lg shadow-lg flex flex-col justify-between ${active ? "border-2 border-indigo-500" : ""
          }`}
      >
        <div
          className={`p-5 box-border flex ${testimonial ? "flex-col-reverse" : "flex-col"
            } items-start justify-between ${active ? "" : "gap-5"} mq825:p-4 mq450:p-3`}
        >
          {/* Title */}
          <h1
            className={`text-white font-montserrat leading-tight w-full ${size || active ? "text-[22px]" : "text-[20px]"
              } mq825:text-[18px] mq450:text-[16px]`}
          >
            {testimonial ? "-" : ""}{title}
          </h1>
          {/* Description */}
          <p
            className={`${active ? "text-[18px]" : "text-smi"} leading-relaxed text-gray-200 break-words overflow-wrap flex-grow mq825:text-base mq450:text-sm`}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

GridColumns.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  size: PropTypes.string,
  testimonial: PropTypes.bool,
  active: PropTypes.bool.isRequired, // Ensure active prop is required and a boolean
};

export default GridColumns;