import PropTypes from "prop-types";

const PricingStartsFrom = ({ className = "" }) => {

    const TextWithGradient = ({ TextComponent }) => <div className="w-[474px] mq575:w-full mt-8 self-center rounded-[35.43px] bg-gradient-to-r from-purple-700 to-pink-500 overflow-hidden flex items-start justify-start pt-[4.4px] px-0 pb-[0.8px] box-border max-w-full z-[1]">
        <div className="h-[47.8px] flex-1 relative max-w-full">
            <div className="absolute top-0 left-[9.3px] mq575:mx-auto mq575:inset-x-0 rounded-[22.14px] bg-black w-[454.2px] mq575:w-11/12 h-[44.2px] z-[1]" />
            <TextComponent className="mq575:w-full" />
        </div>
    </div>

    return (
        <div
            className={`self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-center text-base-3 text-white font-adamina ${className}`}
        >
            <div className="flex flex-col items-end justify-start max-w-full">
                <p className="m-0 relative font-montserrat leading-[40px] z-[1] max-w-[1350px] self-center mq450:leading-7">
                    *A typical page will have around 3-5 medium sections, any more than
                    this will be counted as an additional page. Additional pages are
                    priced at £149 each. Any maintenance or edits done outside of this period would be
                    charged at £75 an hour.
                </p>
                <div className="w-[1140px] self-center flex flex-row items-start justify-end py-0 px-3 box-border max-w-full">
                    <div className="flex-1 flex flex-col items-start justify-start gap-[65px] max-w-full mq950:gap-[16px] mq1425:gap-[32px]">
                        <TextWithGradient TextComponent={({ className }) => <div className={"absolute top-[7.8px] left-0 uppercase font-lato text-[24px] inline-block w-[474px] h-10 z-[2] " + className}>
                            HOSTING PACKAGES
                        </div>} />
                        <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 box-border max-w-full text-46xl-5 font-montserrat">
                            <div className="flex-1 flex flex-col items-center justify-center max-w-full">
                                <div className="relative leading-[95px] font-extrabold z-[1] mq450:text-20xl mq450:leading-[57px] mq950:text-33xl mq950:leading-[75px]">
                                    Starting from just £19 a month.
                                </div>
                                <div className="self-stretch flex flex-row items-start justify-center py-0 box-border max-w-full text-21xl-9 font-poppins">
                                    <div className="w-[893.3px] flex flex-col items-start justify-start gap-[76px] max-w-full mq450:gap-[19px] mq950:gap-[38px]">
                                        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[20.9px] mq900:flex-col mq900:self-center">
                                            <div className="mq900:self-center rounded-sm-1 bg-gray-500 flex flex-col items-end justify-start pt-[14.4px] px-[45px] pb-[13.6px] gap-[12.3px] z-[1] mq450:pl-5 mq450:pr-5 mq450:box-border">
                                                <div className="flex flex-row items-start justify-end mq900:justify-center py-0 w-full">
                                                    <b className="relative z-[2] mq450:text-6xl mq950:text-14xl">
                                                        Domain
                                                    </b>
                                                </div>
                                                <div className="relative text-2xl text-darkgray-300 z-[2] mq450:text-mid">
                                                    + Cost of Domain
                                                </div>
                                            </div>
                                            <div className="flex-1 rounded-sm-1 bg-gray-500 flex flex-col items-end justify-start pt-[26.2px] px-[13px] pb-[13px] box-border gap-[16.4px] min-w-[192px] z-[1]">
                                                <div className="flex flex-row items-end justify-center py-0 w-full">
                                                    <b className="relative z-[2] mq450:text-6xl mq950:text-14xl">
                                                        Unlimited Support
                                                    </b>
                                                </div>
                                                <div className="relative text-2xl text-darkgray-300 z-[2] mq450:text-mid">
                                                    Bug fixing, Error Finding, Maintenance
                                                </div>
                                            </div>
                                            <div className="mq900:self-center rounded-sm-1 bg-gray-500 flex flex-col items-end justify-start pt-[14.4px] px-[45px] pb-[13.6px] gap-[12.3px] z-[1] mq450:pl-5 mq450:pr-5 mq450:box-border">
                                                <div className="flex flex-row items-start justify-start mq900:justify-center py-0 w-full">
                                                    <b className="relative z-[2] mq450:text-6xl mq950:text-14xl">
                                                        Hosting
                                                    </b>
                                                </div>
                                                <div className="relative text-2xl text-darkgray-300 z-[2] mq450:text-mid min-w-[182.5px]">
                                                    Included
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-7xl-6">
                                            <TextWithGradient TextComponent={({ className }) => <div className={"absolute top-[7.8px] left-0 uppercase font-lato text-[24px] inline-block w-[474px] h-10 z-[2] " + className}>
                                                Enterprise Services
                                            </div>} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="m-0 w-[1080px] relative font-montserrat leading-[39.86px] inline-block max-w-full z-[1] mq450:leading-7">
                            Our enterprise packages start at set base prices listed above.
                            Final price is determined after finalising the services required
                            and on the complexity of the project. Don&apos;t suit a specific
                            package? Possible custom packages can be discussed upon request.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

PricingStartsFrom.propTypes = {
    className: PropTypes.string,
};

export default PricingStartsFrom;
