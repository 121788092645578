import PropTypes from "prop-types";
import { Link } from "react-router-dom"

const FooterTop = ({ className = "" }) => {
  return (
    <div
      className={`self-stretch flex flex-col items-start justify-start max-w-full text-center text-45xl text-white font-montserrat ${className}`}
    >
      <div className="self-stretch overflow-hidden flex flex-row items-start justify-center py-[100px] pr-5 pl-[21px] box-border relative gap-[712px] min-h-[626px] mq1250:min-h-[300px] mq1250:py-[50px]  max-w-full z-[1] mq825:gap-[178px] mq825:pb-[65px] mq825:box-border mq1250:gap-[356px] mq450:gap-[89px]">
        <div className="w-[1400px] [backdrop-filter:blur(10px)] [background:linear-gradient(95.76deg,_rgba(255,_255,_255,_0.04),_rgba(255,_255,_255,_0.03)_26.43%,_rgba(255,_255,_255,_0.01)_77.28%,_rgba(255,_255,_255,_0.02))] box-border overflow-hidden shrink-0 flex flex-col items-center justify-start pt-[83px] pb-[37px] pr-[21px] pl-5 gap-[64px] max-w-full z-[1] border-[1px] border-solid border-gray-300 mq825:gap-[16px] mq1250:gap-[32px] mq450:pt-[54px] mq450:pb-6 mq450:box-border">
          <h1 className="m-0 w-[964px] relative text-inherit font-bold font-inherit inline-block max-w-full mq825:text-32xl mq450:text-7xl">
            For more detailed information related to pricing and packaging.
          </h1>
          <div className="w-[964px] flex flex-row items-start justify-center py-0 pr-0 pl-0.5 box-border max-w-full mb-8">
            <Link to="/contact" className="no-underline cursor-pointer [border:none] py-2.5 px-5 bg-[transparent] w-[220px] rounded-31xl [background:linear-gradient(132.03deg,_#c471ed,_#a044ff)] overflow-hidden shrink-0 flex flex-row items-start justify-start box-border whitespace-nowrap">
              <div className="flex-1 relative text-5xl font-poppins text-white text-center">
                Contact Us!
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export const Footer = ({ page }) => <footer className="self-stretch overflow-hidden flex flex-col items-start justify-start pt-[30px] px-[91px] pb-[27px] box-border gap-[20px] max-w-full z-[2] mt-[-8.8px] text-left text-base text-darkgray font-montserrat mq825:pl-[22px] mq825:pr-[22px] mq825:box-border mq1250:pl-[45px] mq1250:pr-[45px] mq1250:box-border">
  <div className="self-center w-[1400px] flex flex-row items-end justify-between gap-[20px] mq1250:flex-wrap mq1250:justify-center mq1450:w-full ">
    <div className="h-[244px] w-fit mq1024:w-full flex flex-col items-start justify-center gap-[25px] shrink-0 [debug_commit:bf4bc93] mq1024:flex-row mq1024:h-fit mq1024:items-center mq1024:justify-center">
      <Link to="/" className="flex" >
        <img
          className="max-w-[86px] mq1024:h-[114px] relative object-contain mr-28 mq550:mr-0"
          loading="lazy"
          alt=""
          src="/image-1--1-removebgpreview-1@2x.png"
        />
      </Link>
      {/* <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-2.5 mq1024:items-center mq1024:justify-center">
        <p className="m-0 h-[52px] flex-1 relative font-medium flex items-center mq1024:w-[200px]">{`Gg. Sempit deket toko 1, daerah Berkoh, Jakarta, di Indonesia `}</p>
      </div> */}
      {/* <div className="flex flex-row items-start justify-start py-0 px-2.5">
        <div className="flex flex-row items-start justify-start gap-[16px]">
          <a href="https://www.linkedin.com/company/vantage-cybertech" target="_blank" className="h-[28px] w-[28px] bg-[#0072b1] rounded-full flex items-center justify-center box-border">
            <img
              className="w-[15px] h-3.5 relative"
              loading="lazy"
              alt=""
              src="/linked-in.svg"
            />
          </a>
          <a href="https://wa.me/447946760003?text=I'm%20ready%20to%20transform%20my%20vision%20into%20a%20stunning%20website.%20Can%20you%20help%20me%20make%20it%20extraordinary?" target="_blank" className="h-[28px] w-[28px] rounded-full flex items-center justify-center box-border">
            <img
              className="w-[100%] h-[100%]"
              loading="lazy"
              alt=""
              src="/w.svg"
            />
          </a>
          <div className="flex flex-row items-start justify-start relative gap-[10px] w-7 h-7">
            <div className="h-7 w-7 relative rounded-[50%] bg-gray-100" />
            <img
              className="h-[78.57%] w-[42.86%] absolute !m-[0] top-[21.43%] right-[28.57%] bottom-[0%] left-[28.57%] max-w-full overflow-hidden max-h-full z-[1]"
              loading="lazy"
              alt=""
              src="/f.svg"
            />
          </div>
        </div>
      </div> */}
    </div>
    <div className="self-center w-[632.6px] flex flex-col items-start justify-center box-border min-h-full max-w-full">
      <div className="self-stretch flex flex-row items-start justify-between shrink-0 [debug_commit:bf4bc93] mq825:flex-wrap gap-y-[20px] mq550:!mx-0">
        <div className="w-40 flex flex-col items-start justify-start py-0 px-0 box-border gap-[15.3px]">
          <b className="self-stretch relative text-lg font-semibold text-white">
            Company
          </b>
          <div className="self-stretch relative text-gray-100"><Link className="no-underline text-purple-500" to="/">Home</Link></div>
          <div className="self-stretch relative text-gray-100"><Link className="no-underline text-purple-500" to="/portfolio">Portfolio</Link></div>
          {/* <div className="self-stretch relative text-gray-100">Career</div>
          <div className="self-stretch relative text-gray-100">About Us</div> */}
        </div>
        <div className="w-40 flex flex-col items-start justify-start gap-[15.3px]">
          <b className="w-28 relative text-lg font-semibold text-white flex items-center">
            Services
          </b>
          <div className="w-28 relative flex items-center text-gray-100"><Link className="no-underline text-purple-500" to="/static">Static</Link></div>
          <div className="w-28 relative flex items-center text-gray-100"><Link className="no-underline text-purple-500" to="/fullstack">Full-Stack</Link></div>
          <div className="w-28 relative flex items-center text-gray-100"><Link className="no-underline text-purple-500" to="/bespoke">Bespoke</Link></div>
        </div>
        <div className="w-40 flex flex-col items-start justify-center gap-[15.3px]">
          <b className="w-[219.7px] relative text-lg font-semibold text-white flex items-center">
            Pages
          </b>
          <div className="self-stretch relative text-gray-100"><Link className="no-underline text-purple-500" to="/faq">FAQ</Link></div>
          <div className="self-stretch relative text-gray-100"><Link className="no-underline text-purple-500" to="/pricing">Pricing</Link></div>
          <div className="self-stretch relative text-gray-100"><Link className="no-underline text-purple-500" to="/contact">Contact Us</Link></div>
        </div>
      </div>
    </div>
  </div>
  <div className="w-full relative text-lgi font-medium text-white text-center flex items-center justify-center max-w-[111%] shrink-0">
    Copyright 2024 VCT.All Reserved
  </div>
</footer>

FooterTop.propTypes = {
  className: PropTypes.string,
};

export default FooterTop;
