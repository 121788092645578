import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const FAQContentComponent = ({ className = "" }) => {

  const GeneralFaqComp = ({ topic, faqs, css }) => {
    return <div className={css}>
      <div className="self-stretch flex flex-row items-center justify-center p-2.5 box-border max-w-full text-32xl text-darkorchid-200">
        <h1 className="m-0 flex-1 relative text-inherit leading-[51px] capitalize font-normal font-inherit inline-block max-w-full mq450:text-12xl mq450:leading-[31px] mq900:text-22xl mq900:leading-[41px]">
          {topic}
        </h1>
      </div>
      {faqs.map((faq, index) => <IndividualFaqComp faq={faq} index={index} />)}
    </div>
  }

  const IndividualFaqComp = ({ faq, index }) => {
    const { ques, answer, isOpen } = faq;
    const [open, setIsOpen] = useState(isOpen);

    const toggleOpen = () => {
      setIsOpen(!open);
    };

    useEffect(() => {
    }, [open]);

    return (
      <div key={index} className={`self-stretch flex flex-col items-start justify-start max-w-full mq450:gap-[23px]`}>
        <div className="self-stretch flex flex-row items-start justify-start relative gap-[10px] max-w-full" onClick={toggleOpen}>
          <div className="flex-1 relative capitalize inline-block mqAllMobile:max-w-[calc(100vw-120px)] mqAllDesktop:max-w-[calc(100%-30px)] mqAll:!max-w-[calc(100%-20px)] mq450:text-xl">
            {ques}
          </div>
          <img
            className={`h-[10.5px] w-[19.3px] absolute !m-[0] top-[8.7px] right-[10px] object-contain`}
            loading="lazy"
            alt=""
            src={open ? "/vector-165-1.svg" : "/vector-165-2.svg"}
          />
        </div>
        <div className={`m-0 self-stretch relative text-6xl-5 text-light-60 mq450:text-xl dropdown_item-1 ${open ? 'open' : ''}`}>
          {answer}
        </div>
      </div>
    );
  };


  return (
    <div
      className={`self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-5 box-border max-w-full text-left text-6xl-6 text-light-100 font-poppins ${className}`}
    >
      <div className="flex-1 flex flex-col items-center justify-center pt-[0.1px] px-5 pb-[0.2px] box-border max-w-full z-[1]">
        <div className="w-[1580px] flex flex-col items-center justify-center pt-[70px] px-0 pb-[69.9px] box-border gap-[116px] max-w-full shrink-0 mq450:gap-[29px] mq900:gap-[58px] mq900:pt-5 mq900:pb-5 mq900:box-border mq1325:pt-[29px] mq1325:pb-[29px] mq1325:box-border">
          <div className="max-w-[1400px] mqAllMobile:w-[calc(100vw-20px)] self-center flex flex-row items-center justify-start py-[30px] px-0 box-border gap-[80px] text-6xl-5 min-w-[350px] text-blue mq450:gap-[20px] mq900:gap-[40px] mq900:pt-5 mq900:pb-5 mq900:box-border mq1325:flex-wrap">
            <div className="mx-5 flex-1 flex flex-col items-start justify-start gap-[28px] mqAllMobile:min-w-[300px] mq1325:min-w-[650px] mq825:min-w-[350px]">
              <div className="w-full relative capitalize inline-block max-w-full mq450:text-xl">
                FAQ
              </div>
              <div className="self-stretch mq1450:text-45xl relative text-75xl-2 leading-[106.18px] mqAllMobile:w-[300px] capitalize font-semibold text-light-100 mq450:text-9xl mq450:leading-[42px] mq1450:leading-[75px] mq900:text-28xl mq900:leading-[64px]">
                Frequently Asked Questions
              </div>
            </div>
            <GeneralFaqComp topic={"General"} faqs={[
              {
                ques: "How long will my project take?",
                answer: "The timeline for developing a web application depends on the complexity and scope of the project. Our simple packages might take a few weeks, while  a more complex application as part of our enterprise package, with multiple features could take several  months. We will provide a detailed project plan and timeline after our  initial consultation to give you a clear understanding of the expected duration.",
                isOpen: false,
              },
              {
                ques: "What technologies do you use for web development?",
                answer: "We typically use the MERN  (MongoDB, Express.js, React.js, Node.js) to develop our web applications. We also leverage cloud services from providers like AWS or Google for deployment and hosting.",
                isOpen: false
              },
              {
                ques: "How will we communicate and track the progress of the project?",
                answer: "We prioritise transparent and regular communication throughout the project. we are happy to schedule regular meetings or provide quick status reports to keep you informed about the project's progress and address any concerns promptly. we can also share video demonstrations for significant updates for the project.",
                isOpen: false
              },
              {
                ques: "Can you integrate third-party services and APIs into my web application?",
                answer: "Absolutely. We have extensive experience in integrating various third-party services and APIs to enhance the functionality of your web application. Whether you need payment gateways, social media integrations, analytics tools, or other external services, we can seamlessly incorporate them to meet your business requirements.",
                isOpen: false
              }
            ]} css={"w-full mx-5 flex flex-col items-start justify-start gap-[43px] min-w-[300px] mq1450:min-w-[800px] mq900:min-w-[340px] mq825:w-11/12 max-w-full text-6xl-6 text-light-100 mq450:gap-[21px] mq1325:flex-1"} />
          </div>

          <div className="max-w-[1400px] mx-5 self-center flex flex-row-reverse items-start justify-start py-[30px] px-0 box-border gap-[79px] mq450:gap-[20px] mq900:gap-[39px] mq900:pt-5 mq900:pb-5 mq900:box-border mq1325:flex-wrap">
            <div className="flex-1 flex flex-col items-start justify-start py-2.5 pr-0 pl-2.5 box-border min-w-[383px] max-w-full mq450:min-w-full mq450:order-1">
              <img className="self-stretch h-[573.4px] relative rounded-[11.33px] max-w-full overflow-hidden shrink-0 object-cover" loading="lazy" alt="" src="/paymentfaq1.png" />
            </div>
            <GeneralFaqComp topic={"Payment"} faqs={[
              {
                ques: "What is the payment structure for a project?",
                answer: "Typically, we require an initial deposit to begin the project, followed by milestone-based payments as the project progresses.",
                isOpen: false,
              },
              {
                ques: "Are there any hidden costs I should be aware of?",
                answer: "No, we believe in transparent pricing and provide a detailed quote that  outlines all costs involved in the project. This includes development,  design, testing, and any third-party services or licenses required. We  make sure to communicate any potential additional costs upfront, so  there are no surprises later on.",
                isOpen: false
              },
              {
                ques: "Do you provide a free consultation or project estimate?",
                answer: "Yes, we offer a free initial consultation to understand your project requirements and provide a rough estimate of the cost and timeline. This helps you get a clear idea of what to expect before committing to a detailed project plan. Contact us to schedule your free consultation and discuss your web development needs.",
                isOpen: false
              },
              {
                ques: "Do you offer fixed-price or hourly billing?",
                answer: "We offer both fixed-price and hourly billing models, depending on the nature and scope of your project. For our set packages, we will give you a quoted price for the completion of the project meeting all your requirements and any adjustments noted in the package. any additional hours will be communicated before and charged at a set hourly rate. For projects with evolving requirements or a need for ongoing development, an hourly billing model offers flexibility. We will discuss the best approach during our initial consultation.",
                isOpen: false
              }
            ]} css={"flex-1 flex flex-col items-start justify-start gap-[45px] min-w-[584px] max-w-full mq450:gap-[22px] mq900:min-w-full mq450:order-2"} />
          </div>

          <div className="max-w-[1400px] mx-5 self-center flex flex-row items-start justify-start py-[30px] px-0 box-border gap-[79px] mq450:gap-[20px] mq900:gap-[39px] mq900:pt-5 mq900:pb-5 mq900:box-border mq1325:flex-wrap">
            <div className="flex-1 flex flex-col items-start justify-start py-2.5 pr-0 pl-2.5 box-border min-w-[383px] max-w-full mq450:min-w-full mq450:order-1">
              <img className="self-stretch h-[573.4px] relative rounded-[11.33px] max-w-full overflow-hidden shrink-0 object-cover" loading="lazy" alt="" src="/support1.png" />
            </div>
            <GeneralFaqComp topic={"Maintenance and Support"} faqs={[
              {
                ques: "What kind of post-launch support do you provide?",
                answer: "We offer comprehensive post-launch support to ensure your web application runs smoothly. This includes bug fixes, performance monitoring, security updates, and error finding for 7 days as part of our set packages. However we offer a monthly maintenance, support and hosting package as a subscription. ",
                isOpen: false,
              },
              {
                ques: "How do you handle emergency issues or downtime?",
                answer: "We have a dedicated support team available to address emergency issues and minimise downtime as soon as possible.",
                isOpen: false
              },
              {
                ques: "Will you provide training for us to manage the application?",
                answer: "Yes, we offer training sessions to ensure you and your team are comfortable managing the application. We love to maintain our relationship with our customers so we will always available for any questions or guidance.",
                isOpen: false
              },
              {
                ques: "What is your process for handling feature requests or enhancements?",
                answer: "As part of the package you have selected there will be a number of revisions included. If the request falls outside of this category we will be happy to have another consultation to highlight requirements, and provide a quoted price upon completion of the feature request. we will then integrate the new features into the development cycle. We provide regular updates on the progress and involve you in the key decision-making steps.",
                isOpen: false
              }
            ]} css={"flex-1 flex flex-col items-start justify-start gap-[45px] min-w-[584px] max-w-full mq450:gap-[22px] mq900:min-w-full mq450:order-2"} />
          </div>
        </div>
      </div>
    </div>
  );
};

FAQContentComponent.propTypes = {
  className: PropTypes.string,
};

export default FAQContentComponent;
