import { Footer } from "../components/FooterTop";
import Header from "../components/Header";
import PackageFeatures from "../components/PackageFeatures";

const VCTBespoke = () => {
    return (
        <div className="w-full relative bg-black overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
            <div className="w-[2487px] h-[7910px] absolute !m-[0] left-[-291px] flex items-center justify-center -z-10">
                <img
                    className="w-full h-full object-contain absolute left-[0px] top-[-86px] [transform:scale(1.022)]"
                    alt=""
                    src="/element.svg"
                />
            </div>
            <Header />
            <div className="self-stretch flex flex-col items-start justify-start gap-[40px] max-w-full text-left text-45xl mq900:gap-[20px] mt-8">
                <div className="max-w-[1450px] self-center flex flex-row items-center justify-center gap-[20px] mq1725:flex-wrap w-full">
                    <h1 className="m-0 w-[690px] relative text-inherit font-bold font-inherit inline-block shrink-0 min-w-[690px] max-w-full z-[1] mq450:text-19xl mq900:text-32xl mq1325:min-w-full mq1725:flex-1 text-white text-center font-montserrat mq550:m-1 mq550:text-19xl">Bespoke Software.<br /> Its an art to us.</h1>
                    <div className="w-[690px] flex flex-col items-start justify-start px-0 pb-0 box-border min-w-[690px] max-w-full text-center text-base text-gray-400 mq900:min-w-full mq1725:flex-1">
                        <div className="self-stretch flex flex-col items-start justify-start gap-[46px] mq450:gap-[23px]">
                            <p className="m-0 self-stretch relative whitespace-pre-wrap shrink-0 [debug_commit:bf4bc93] font-poppins z-[1] mq550:m-2 ">
                                <span>We are continuously pushing boundaries and innovating on the front lines of technology. We have worked on a number of innovative solutions.</span><br />
                                <span>We would love to bring your ideas and vision to life, contact us to discuss further!</span>
                            </p>
                            {/* <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 text-xl text-light-100">
                                <div className="w-[222px] rounded-3xs bg-darkorchid-200 flex flex-row items-start justify-start pt-[21px] pb-[15.3px] pr-0.5 pl-0 box-border shrink-0 [debug_commit:bf4bc93] whitespace-nowrap z-[1]">
                                    <div className="h-[76.9px] w-[222px] relative rounded-3xs bg-darkorchid-200 hidden" />
                                    <h3 className="m-0 h-[40.6px] flex-1 relative text-inherit font-normal font-inherit inline-block z-[1]">
                                        Discover More
                                    </h3>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="self-stretch flex flex-row items-start py-0 px-[70px] box-border max-w-full mq1325:pl-[35px] mq1325:pr-[35px] mq1325:box-border mt-14 justify-center">
                <div className="flex-1 rounded-21xl max-w-[1460px] flex flex-row items-start justify-start pt-[314px] px-[680px] pb-[313px] box-border gap-[10px] bg-[url('/public/Play.png')] bg-cover bg-no-repeat bg-[top] z-[1] mq450:pl-5 mq450:pr-5 mq450:box-border mq900:pt-[204px] mq900:px-[170px] mq900:pb-[203px] mq900:box-border mq1550:pl-[340px] mq1550:pr-[340px] mq1325:box-border">
                    <img
                        className="h-[727px] w-[1603px] relative rounded-21xl object-cover hidden max-w-full"
                        alt=""
                        src="/Play.png"
                    />
                </div>
            </div>
            {/* <div className="max-w-full self-stretch flex flex-row items-start justify-center py-0 px-[7px] box-border text-left text-5xl text-gray-400 mqAll:mx-24 mx-8 my-12">
                <div className="max-w-[1400px] self-center flex-1 flex flex-row items-start justify-between gap-[20px] z-[1] mq1325:flex-wrap">
                    <div className="flex flex-col items-start justify-start gap-[3px]">
                        <h2 className="m-0 relative text-inherit font-normal font-inherit mq450:text-lgi">
                            Data Analytics
                        </h2>
                        <a className="[text-decoration:none] relative text-17xl text-light-100 mq450:text-3xl mq900:text-10xl">
                            458,999+
                        </a>
                    </div>
                    <div className="flex flex-col items-start justify-start gap-[3px]">
                        <h2 className="m-0 relative text-inherit font-normal font-inherit mq450:text-lgi">
                            Machine Learning
                        </h2>
                        <div className="relative text-17xl text-light-100 mq450:text-3xl mq900:text-10xl">
                            546,999+
                        </div>
                    </div>
                    <div className="flex flex-col items-start justify-start gap-[3px]">
                        <h2 className="m-0 relative text-inherit font-normal font-inherit mq450:text-lgi">
                            Computer Vision
                        </h2>
                        <div className="relative text-17xl text-light-100 mq450:text-3xl mq900:text-10xl">
                            987,999+
                        </div>
                    </div>
                    <div className="flex flex-col items-start justify-start gap-[3px]">
                        <h2 className="m-0 relative text-inherit font-normal font-inherit mq450:text-lgi">
                            Natural Language
                        </h2>
                        <div className="relative text-17xl text-light-100 mq450:text-3xl mq900:text-10xl">
                            231,999+
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="flex-1 w-full justify-center items-center">
                <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 font-montserrat my-12 text-45xl text-light-100">
                    <h1 className="m-0 relative text-inherit font-bold font-inherit z-[1] mq450:text-19xl mq900:text-32xl text-center mq550:text-14xl ">
                        Our Projects Involve...
                    </h1>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-[11px] box-border gap-[50px] max-w-full text-3xl-3 mq900:gap-[25px]">
                    <PackageFeatures data={[{ title: "A.I.", desc: "Generative A.I. and leveraging A.I. in other everyday tools.", image1: "/ai-chip-spark--chip-processor-artificial-intelligence-ai.svg" }, { title: "Data Analytics", desc: "Solutions of efficient data capture and distribution.", image1: "/graph-bar-increase--up-product-performance-increase-arrow-graph-business-chart.svg" }, { title: "Finance", desc: "Payment systems and innovative financial solutions for credit.", image1: "/credit-card-1--credit-pay-payment-debit-card-finance-plastic-money-atm.svg" }, { title: "Marketing", desc: "Marketing content generation and management tools.", image1: "/annoncement-megaphone.svg" }, { title: "Retail", desc: "Queue skipping solutions and in-shop Scan & Go application.", image1: "/shopping-bag-hand-bag-2--shopping-bag-purse-goods-item-products.svg" }, { title: "Computer Vision", desc: "Automatic product identification systems that trigger programs or actions.", image1: "/visible--eye-eyeball-open-view.svg" }, { title: "Machine Learning", desc: "Solutions that learn specific behaviour to aid in automating tedious everyday tasks.", image1: "/artificial-intelligence-spark--brain-thought-ai-automated-ai.svg" }, { title: "Full Combination!", desc: "Integrating all techniques to create CRMs, SaaS products and other software solutions for businesses and individuals.", image1: "/ai-technology-spark--lightbulb-idea-bright-lighting-artificial-intelligence-ai.svg" }]} />
                </div>
            </div>
            <Footer page={"static"} />
        </div>
    );
};

export default VCTBespoke;
