import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const showSwal = (
    errorTitle,
    errorMessage,
    status,
    navigateCallback
) => {
    withReactContent(Swal).fire({
        icon: status >= 200 && status < 300 ? "success" : "error",
        title: errorTitle,
        text: errorMessage,
    });
    if (navigateCallback) {
        setTimeout(() => {
            navigateCallback();
        }, 1000);
    }
    if (errorMessage == "Invalid token or expired") {
        setTimeout(() => {
            window.location.href = '/loginPage'
        }, 1000);
    }
};