import axios from "axios";

export const api = (token) =>
    axios.create({
        baseURL: "https://3.11.95.10:4600",
        timeout: 19000,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
    });

export const contact = (data) => api(undefined).post("/send-email", data).then(data => data.data);