import PropTypes from "prop-types";
import { Link } from "react-router-dom"
import { useBrowser } from "../pages/Provider/BrowserContext";

const FrameComponent2 = ({ className = "" }) => {
  const myBrowserIsSafari = useBrowser().browser.includes("Safari")
  return (
    <div
      className={`self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[119px] box-border max-w-full text-left text-51xl-5 text-white font-montserrat mq825:pb-[77px] mq825:box-border mq450:pb-[50px] mq450:box-border ${className}`}
    >
      <div className="flex-1 overflow-hidden flex flex-col items-start justify-start py-0 px-[75px] box-border max-w-full z-[1] mq1250:pl-[37px] mq1250:pr-[37px] mq1250:box-border">
        <div className="self-center flex flex-row items-center justify-between max-w-[1920px] gap-[20px] mq1550:flex-wrap mq1450:mt-8">
          <div className="w-[716px] mq400:w-[370px] mq450:w-[395px] flex flex-col items-start justify-center py-5 px-0 box-border min-w-[716px] max-w-full mq825:gap-[31px] mq1250:min-w-full mq1550:flex-1 mq450:gap-[15px]">
            <div className="self-stretch flex flex-col items-start justify-center gap-[35.2px] max-w-full mq825:gap-[18px] mq550:w-[calc(100vw_-_10px)] mq550:self-center ">
              <b className="self-stretch relative font-montserrat [text-shadow:0px_5.5px_5.51px_rgba(0,_0,_0,_0.25)] mq825:text-37xl mq450:text-12xl mq1250:!text-center mq400:text-7xl mq550:text-19xl ">
                Transform Your Business's Online Potential
              </b>
              <div className="w-[620.2px] relative text-7xl-4 font-poppins justi flex items-center text-start mq1250:text-center max-w-full mq450:text-2xl mq400:text-xl mq1250:w-full mq1250:justify-center mq825:text-3xl mq750:self-center mq750:max-w-[500px] mq575:min-w-[425px] mq450:min-w-[325px] mq1550:text-start mq750:text-center">
                Expert web development services to elevate your business.
              </div>
              <div className="mq1250:w-full mq1250:justify-center mq1250:flex">
                <button className="cursor-pointer [border:none] p-0 bg-[transparent] w-[176.2px] flex flex-row items-center justify-center">
                  <Link to="/pricing" className="no-underline flex-1 rounded-[55.08px] [background:linear-gradient(132.03deg,_#c471ed,_#a044ff)] overflow-hidden flex flex-row items-start justify-start px-[22px] py-[11px] whitespace-nowrap">
                    <div className="flex-1 relative text-lgi-8 font-poppins text-white text-center ">
                      Get Started
                    </div>
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div className={`flex ${myBrowserIsSafari ? "mq1550:hidden mq1024:flex" : "mq1325:hidden mq900:flex"} justify-center w-full`}>
            <img
              className="w-[656.9px] relative max-h-full overflow-hidden shrink-0 max-w-full mq1550:flex-1 mq750:max-w-[575px] min-w-[350px] mq575:max-w-[450px] mq450:max-w-[350px] "
              loading="lazy"
              alt=""
              src="/frame-770744.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
