import PropTypes from "prop-types";

const NotFound = ({ className = "" }) => {
    return (
      <div
        className={`w-full h-[900px] flex flex-row items-start justify-center py-0 px-5 box-border text-center text-23xl-3 text-light font-montserrat ${className}`}
      >
        <div className="w-[896px] flex flex-col items-center justify-start pt-0 px-0 pb-0 box-border relative gap-[38px] max-w-full z-[1] mq450:gap-[19px]">
          <div className="self-stretch flex flex-col items-center justify-start max-w-full">
            <div className="self-stretch flex flex-col items-center justify-start">
              <div className="self-stretch flex flex-row items-center justify-center py-2.5 px-5">
                <div className="h-[52px] font-montserrat text-29xl font-bold text-white relative tracking-[0.1em] uppercase inline-block mq450:text-6xl mq1050:text-15xl">
                  OUCH! WE'RE SORRY
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start p-2.5 box-border">
                <img
                  className="self-stretch flex-1 relative overflow-hidden h-[400px] object-cover"
                  loading="lazy"
                  alt=""
                  src="/404.svg"
                />
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-center py-2.5 px-[27px] box-border max-w-full text-7xl-4 font-poppins">
              <p className="m-0 flex-1 relative leading-[46.15px] font-montserrat font-bold text-white inline-block max-w-full mq450:text-2xl mq450:leading-[37px]">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live.
              </p>
            </div>
          </div>
          <div className="w-full !m-[0] flex flex-col items-center justify-center py-2.5 px-5 box-border max-w-full">
            <button className="cursor-pointer [border:none] pt-[29.5px] px-5 pb-[29.4px] bg-[transparent] w-[362.6px] [background:linear-gradient(#c06cef,_#c06cef),_linear-gradient(90deg,_#ff1167,_#dd21b4)] flex flex-row items-center justify-center box-border whitespace-nowrap max-w-full hover:bg-mediumorchid rounded-xl">
              <div className="h-[35px] text-4xl-1 tracking-[0.1em] uppercase font-poppins text-center inline-block text-7xl-4 font-extrabold">
                back to home
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  NotFound.propTypes = {
    className: PropTypes.string,
  };
  
  export default NotFound;
  