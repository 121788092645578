import ContactCard from "../components/ContactCard";
import Header from "../components/Header";
import { Footer } from "../components/FooterTop";
import NotFound from "../components/NotFound";

const ContactUsPage = () => {
    return (
        <div className="w-full relative bg-black overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
            <div className="w-[2487px] h-[7910px] absolute !m-[0] bottom-[-3620px] left-[-291px] flex items-center justify-center z-[0]">
                <img
                    className="w-full h-full object-contain absolute left-[0px] top-[-86px] [transform:scale(1.022)]"
                    alt=""
                    src="/element.svg"
                />
            </div>
            <Header />
            <div className="self-stretch flex flex-col items-end justify-start gap-[20px] max-w-full">
                <NotFound />
            </div>
            <Footer />
        </div>
    );
};

export default ContactUsPage;
