import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CardColumns = ({
  className = "",
  cardColumnOne,
  staticWebsiteDevelopment,
  fastSecureAndSEOFriendlyS,
  toLink,
}) => {
  return (
    <div className={`flex flex-col items-start justify-between py-0 px-4 box-border gap-8 text-left text-lg text-white font-montserrat mq450:gap-4 mq450:px-2 ${className}`}>
      {/* Image */}
      <img
        className="h-[400px] mq450:h-[350px] !w-[90%] self-center object-cover rounded-md"
        loading="lazy"
        alt={staticWebsiteDevelopment}
        src={cardColumnOne}
      />

      {/* Text Content */}
      <div className="flex flex-col items-start justify-start gap-6 mq450:gap-4 text-center mq450:text-left">
        <h1 className="m-0 self-stretch relative text-inherit font-normal font-inherit mq825:text-7xl mq450:text-xl">
          {staticWebsiteDevelopment}
        </h1>
        <p className="m-0 self-stretch relative text-base font-poppins text-lightgray-100 text-justify">
          {fastSecureAndSEOFriendlyS}
        </p>
        {/* Learn More Button */}
        <button className="cursor-pointer py-2 pr-[54px] pl-[55px] bg-[transparent] rounded-31xl overflow-hidden flex flex-row items-start justify-start whitespace-nowrap border-[1px] border-solid border-white hover:bg-gainsboro-300 hover:border-gainsboro-100">
          <Link to={toLink} className="no-underline relative text-base font-poppins text-white inline-block min-w-[89px]">
            Learn More
          </Link>
        </button>
      </div>
    </div>
  );
};

CardColumns.propTypes = {
  className: PropTypes.string,
  cardColumnOne: PropTypes.string.isRequired,
  staticWebsiteDevelopment: PropTypes.string.isRequired,
  fastSecureAndSEOFriendlyS: PropTypes.string.isRequired,
  toLink: PropTypes.string.isRequired,
};

export default CardColumns;