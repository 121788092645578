import PropTypes from "prop-types";

const PlanCard = ({ title, price, features, index }) => {
    return (
        <div className="flex flex-col items-center justify-start max-w-full gap-[10px] mb-8">
            {/* <div className="flex flex-col items-center justify-start relative max-w-full gap-[10px] mb-8 bg-gray-800 border-solid border-gray-100 border-[1px] rounded-xl">
            <img
                className="relative rounded-[25px] max-w-full"
                alt={title}
                src={index == 2 ? "/rectangle-21.svg" : "/rectangle-20.svg"}
            /> */}
            <div className={"w-[275px] top-[42px] flex flex-col items-center justify-between min-h-[350px] max-w-full z-[1] border-solid border-gray-100 border-[1px] rounded-xl p-6 mq450:p-4 " + (index == 2 ? "bg-gradient-to-r from-[#241734] via-[#312341] to-[#241734]" : "bg-gradient-to-r from-[#CD2DC2] to-[#F31F8F]")}>
                {index == 2 ? (<div className="w-fit self-center rounded-[16.03px] [background:linear-gradient(152.01deg,_#b2ebf2,_#d1c4e9_65.35%,_#f8bbd0)] flex flex-col items-center justify-start py-[10px] px-[20px]">
                    <b className="text-3xl uppercase font-bold text-gray-500">{title}</b>
                    <b className="uppercase text-left text-blue">Most Popular</b>
                    <div className="flex flex-row items-start justify-center gap-[10px] text-5xl text-black">
                        <b>£ {price}</b>
                    </div>
                </div>) : (<>
                    <button className="cursor-pointer [border:none] py-1 px-[33px] bg-[linear-gradient(152.01deg,_#b2ebf2,_#d1c4e9_65.35%,_#f8bbd0)] w-[227.6px] h-[50.1px] rounded-[18.77px] flex items-center justify-center">
                        <b className="text-3xl uppercase font-montserrat text-gray-500">{title}</b>
                    </button>
                    <div className="flex flex-row items-center justify-center gap-[10px] text-5xl">
                        <b>£ {price}</b>
                    </div>
                </>)}
                {/* <button className="cursor-pointer [border:none] py-[18.5px] px-5 bg-light-100 w-full rounded-[8.22px] flex items-center justify-center hover:bg-gainsboro">
                    <div className="text-xl font-montserrat font-[900] text-black">Subscribe</div>
                </button> */}
                <div className="flex flex-col items-start justify-center py-0 px-1 box-border gap-[20px] w-full text-left text-sm font-adamina mq450:flex-wrap">
                    {features.map((feature, index) => (
                        <div key={index} className="flex flex-row items-center justify-start gap-[5px] mq450:text-base">
                            <img
                                className="w-[25px] h-[26px] ml-5 relative object-cover"
                                alt=""
                                src={feature.checked ? "/checked-6-1@2x.png" : "/cross-1@2x.png"}
                            />
                            <div className="text-[15px] font-montserrat">{feature.title}</div>
                        </div>
                    ))}
                </div>
            </div>
            {/* </div> */}
        </div>
    );
};

PlanCard.propTypes = {
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Plans = ({ className = "" }) => {
    const plans = [
        {
            title: "Starter",
            price: "899",
            features: [
                { title: "For 1 Landing Page", checked: true },
                { title: "Basic Responsiveness", checked: true },
                { title: "Design Not included", checked: false },
                { title: "7-Day Support with Bug fixing, Error Finding, 2 Revisions", checked: true }
            ],
        },
        {
            title: "Premium",
            price: "2399",
            features: [{ title: "For Up to 5 Pages", checked: true }, { title: "Fully Mobile Responsive", checked: true }, { title: "Included Figma Designs and Wireframes", checked: true }, { title: "7-Day Support with Bug fixing, Error Finding, 2 Revisions", checked: true }],
        },
        {
            title: "Pro",
            price: "2999",
            features: [{ title: "Up to 8 Pages", checked: true }, { title: "Fully Mobile + Tablet Responsive", checked: true }, { title: "Figma Designs and Wireframes including Mobile + Tablet Screens", checked: true }, { title: "7-Day Support Bug Fixing, Error Finding, 3 Revisions", checked: true }],
        },
        {
            title: "Enterprise",
            price: "6599+",
            features: [{ title: "8+ Pages", checked: true }, { title: "Fully Responsive", checked: true }, { title: "Figma Designs and Wireframes including Mobile + Tablet Screens", checked: true }, { title: "7-Day Support with Bug Fixing, Error Finding, 3 Revisions", checked: true }],
        },
    ];

    return (
        <div className={`self-stretch flex flex-col items-center justify-start pt-0 px-0.5 pb-[45px] box-border max-w-full text-center text-5xl text-light-100 font-montserrat lg:pb-[29px] ${className}`}>
            <div className="w-[1400px] flex-wrap overflow-hidden items-start justify-around box-border gap-[10px] max-w-full grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 py-[100px] px-[10px] lg:py-[64px] lg:px-[25px] mq450:pt-16 mq450:pb-16 mq825:grid-cols-1 mq1450:grid-cols-2">
                {plans.map((plan, index) => (
                    <PlanCard key={index} index={index} title={plan.title} price={plan.price} features={plan.features} />
                ))}
            </div>
        </div>
    );
};

Plans.propTypes = {
    className: PropTypes.string,
};

export default Plans;
