
import PropTypes from "prop-types";

export const RightImage = ({ title, desc, image }) => <div className="self-stretch flex flex-row mq1157:flex-col-reverse items-center justify-center gap-[74px] max-w-full mq450:gap-[18px] mq900:gap-[37px] mq1725:flex-wrap">
  <div className="flex-[0.8289] flex flex-col items-start justify-start py-5 pl-0 box-border gap-[26px] min-w-[522px] max-w-full mq450:pr-5 mq450:box-border mq900:box-border mq900:min-w-full mq1725:flex-1 mb-8">
    <div className="self-stretch flex flex-row items-center justify-start p-2.5 box-border max-w-full">
      <h1 className="m-0 flex-1 relative text-inherit font-inherit inline-block max-w-full mq450:text-5xl mq900:text-13xl font-montserrat font-bold">
        {title}
      </h1>
    </div>
    <div className="self-stretch flex flex-row items-start justify-start p-2.5 box-border max-w-full text-justify text-3xl font-poppins">
      <p className="m-0 flex-1 relative inline-block max-w-full mq450:text-lg">
        {desc}
      </p>
    </div>
  </div>
  <div className="flex-1 flex flex-col items-start justify-start p-2.5 box-border min-w-[522px] max-w-full mq900:min-w-full mq1725:flex-1">
    <img
      className="self-stretch h-[434.5px] mq575:h-[400px] relative rounded-12xl max-w-full overflow-hidden shrink-0 object-cover"
      loading="lazy"
      alt=""
      src={image}
    />
  </div>
</div>

export const LeftImage = ({ title, desc, image }) => <div className={"self-stretch flex flex-row items-center justify-center gap-[77px] max-w-full mq450:gap-[19px] mq900:gap-[38px] mq1725:flex-wrap mb-8"}>
  <div className="flex-[0.975] flex flex-col items-start justify-between p-2.5 box-border min-w-[521px] max-w-full mq900:min-w-full mq1725:flex-1">
    <img
      className="self-stretch h-[442px] mq575:h-[400px] relative rounded-12xl max-w-full overflow-hidden shrink-0 object-cover"
      loading="lazy"
      alt=""
      src={image}
    />
  </div>
  <div className="flex-1 flex flex-col items-start justify-between py-5 px-0 box-border min-w-[521px] min-h-[300px] max-w-full mq900:min-w-full mq1725:flex-1 mq1725:min-h-[auto]">
    <h1 className="m-0 self-stretch relative text-inherit font-inherit mq450:text-5xl mq900:text-13xl font-montserrat font-bold mq1157:mb-11">
      {title}
    </h1>
    <p className="m-0 self-stretch relative text-3xl font-poppins text-justify mq450:text-lg">
      {desc}
    </p>
  </div>
</div>

const OurProcess = ({ className = "" }) => {

  return (
    <div className="flex-1 overflow-hidden flex flex-col items-center justify-center py-[20.5px] px-5 box-border gap-[62px] max-w-full z-[1] mq450:gap-[15px] mq900:gap-[31px] mq1325:pt-5 mq1325:pb-5 mq1325:box-border">
      <h1 className="m-0 w-[1680px] relative text-inherit font-bold font-inherit inline-block max-w-full mq450:text-[36px] mq900:text-29xl mq1920:text-32xl mqAll:text-37xl text-white text-center mt-12 mb-8">
        Our Process
      </h1>
      <div
        className={`w-[1680px] flex flex-col items-center justify-start gap-[60px] max-w-full text-left text-21xl text-white font-montserrat mq900:gap-[30px] ${className}`}
      >
        <LeftImage title={`Pick a Template or Share Inspiration`} desc={`Start by choosing from our collection of templates or share your
                inspiration with us. We'll help you select a design that suits
                your needs and vision.`} image={"/rectangle-121@2x.png"} />
        <RightImage title={`Detailed Wireframes`} desc={`We create detailed wireframes to give you a clear picture of the website layout and functionality. Your feedback is crucial at this stage to ensure we are aligned with your vision.`} image={"/rectangle-13@2x.png"} />
        <LeftImage title={`Your Approval & Satisfaction`} desc={`After reviewing the wireframes, you give us the go-ahead to proceed. We ensure that you are completely satisfied with the planned structure before we start development.`} image={"/rectangle-14@2x.png"} />
        <RightImage title={`Development, Hosting and Domain Setup`} desc={`Our team develops the website, ensuring it meets the highest standards. We also take care of hosting and domain setup, providing you with a fully functional and live website.`} image={"/rectangle-15@2x.png"} />
        <LeftImage title={`Future Support`} desc={`Launching your website is just the beginning. We understand that maintaining and optimising your site is crucial for its success. That's why we offer comprehensive future support to ensure your website continues to perform at its best.`} image={"/rectangle-16@2x.png"} />
      </div>
    </div>
  );
};

OurProcess.propTypes = {
  className: PropTypes.string,
};

export default OurProcess;
