import ContactCard from "../components/ContactCard";
import Header from "../components/Header";
import { Footer } from "../components/FooterTop";
import { Link } from "react-router-dom"
import GetInTouch from "../components/GetInTouch";

const ContactUsPage = () => {
    return (
        <div className="w-full relative bg-black overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
            <div className="w-[2487px] h-[7910px] absolute !m-[0] bottom-[-3620px] left-[-291px] flex items-center justify-center z-[0]">
                <img
                    className="w-full h-full object-contain absolute left-[0px] top-[-86px] [transform:scale(1.022)]"
                    alt=""
                    src="/element.svg"
                />
            </div>
            <Header />
            <div className="self-stretch flex flex-col items-end justify-start gap-[20px] max-w-full">
                <div className="self-stretch overflow-hidden flex flex-col items-center justify-between py-[78px] px-5 box-border min-h-[406px] mq450:min-h-[325px] max-w-full z-[1] mq450:pt-[51px] mq450:pb-[51px] mq450:box-border ">
                    <div className="w-[748px] flex flex-col items-center justify-start gap-[15px] max-w-full">
                        <Link to="/">
                            <img
                                className="w-[197px] h-[116px] relative object-cover"
                                loading="lazy"
                                alt=""
                                src="/image-7removebgpreview-1@2x.png"
                            />
                        </Link>
                        <div className="self-stretch flex flex-col items-center justify-start gap-[35px] max-w-full mq750:gap-[17px]">
                            <b className="relative leading-[40px] font-montserrat text-white text-32xl font-bold inline-block max-w-full mq450:text-[34px] mq450:leading-[24px] mq1050:text-[45px] mq1050:leading-[32px]">
                                Contact Us
                            </b>
                            <p className="m-0 self-stretch h-11 relative text-base leading-[22px] font-poppins text-gray-400 text-center flex items-center justify-center shrink-0">
                                The best way to reach us is by dropping us an email or a
                                message using the form below.
                            </p>
                        </div>
                    </div>
                </div>
                <GetInTouch className="mb-12 mt-[-25px]" removeGetInTouch={false} />
                {/* <GetInTouch */}
            </div>
            <Footer />
        </div>
    );
};

export default ContactUsPage;
