import React, { createContext, useState, useContext, useEffect } from "react";
import { getBrowserInfo } from "../../Helper/GetBrowser";

// Create the context
const BrowserContext = createContext();

// Create a custom hook to use the context
export const useBrowser = () => useContext(BrowserContext);

// Create a provider component
export const BrowserProvider = ({ children }) => {
    const [browser, setBrowser] = useState("");
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        // Set browser info
        setBrowser(getBrowserInfo().browserName);

        // Function to update window dimensions
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Call handleResize once to set initial dimensions
        handleResize();

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <BrowserContext.Provider value={{ browser, windowDimensions }}>
            {children}
        </BrowserContext.Provider>
    );
};
