import StaticHero from "../components/StaticHero";
import OurProcess from "../components/OurProcess";
import { Footer } from "../components/FooterTop";
import Header from "../components/Header";
import OurExpertise from "../components/OurExpertise";
import GetInTouch from '../components/GetInTouch'

const VCTStaticPage = () => {
  return (
    <div className="w-full relative bg-black overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <div className="w-[2487px] h-full absolute !m-[0] left-[-291px] flex items-center justify-center z-[0]">
        <img
          className="w-full h-full object-contain absolute left-[0px] top-[-86px] [transform:scale(1.022)]"
          alt=""
          src="/element.svg"
        />
      </div>
      <Header />
      <div className="self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[9px] box-border max-w-full">
        <div className="flex flex-col items-start justify-start gap-[50px] max-w-full mq900:gap-[25px]">
          <StaticHero />
        </div>
      </div>
      <div className="self-center w-[1400px] flex flex-row items-start justify-start pt-0 px-0 pb-[148px] box-border max-w-full font-montserrat mq900:pb-2 mq900:box-border mq1325:pb-[62px] mq1325:box-border">
        <OurProcess />
      </div>
      <div className="w-full flex flex-row items-start justify-center pt-0 px-5 pb-[132px] box-border max-w-full text-left text-5xl mq900:pb-14 mq900:box-border mq1325:pb-[86px] mq1325:box-border">
        <div className="w-[1400px] flex flex-row items-end justify-start gap-[128px] max-w-full mq450:gap-[16px] mq900:gap-[32px] mq1325:flex-wrap mq1325:gap-[64px]">
          <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-px box-border min-w-[483px] max-w-full mq900:min-w-full">
            <div className="self-stretch flex flex-row items-start justify-start p-2.5 box-border shrink-0 [debug_commit:bf4bc93] max-w-full z-[1]">
              <img
                className="h-[889px] flex-1 relative mq575:h-[400px] rounded-[40px] max-w-full overflow-hidden object-cover"
                alt=""
                src="/rectangle-12-1@2x.png"
              />
            </div>
          </div>
          <OurExpertise />
        </div>
      </div>
      <GetInTouch />
      <Footer page={"static"} />
    </div>
  );
};

export default VCTStaticPage;
